import * as React from 'react'
import { Header, Container, Segment, Icon, Image } from 'semantic-ui-react'
import { withLayout } from '../components/Layout'
import { Grid } from 'semantic-ui-react'
import { Typography } from '@material-ui/core'
const homeDrivewayImg = require('../../assets/home-driveway.jpg')
const concreteRemovalImg = require('../../assets/concrete-removal.jpg')

const AboutPage = () => {
  return (
    <Container>
      <Segment vertical>
        <Header as="h1">
          <Icon name="wrench" />
          <Header.Content>Our Services</Header.Content>
        </Header>
      </Segment>
      <br />
      <Segment vertical>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={8}>
              <Image
                src={homeDrivewayImg}
                alt="driveway"
                size="huge"
                rounded={true}
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <Header as="h3">
                <Header.Content>
                  Driveway Concrete Installation & Replacement
                </Header.Content>
              </Header>
              <Typography>
                A polished and attractive driveway has an improving effect on
                the overall value and appearance of your home. A well
                constructed concrete driveway is the first impression to a well
                constructed home - and options for stamped, colored, polished,
                or custom concrete designs allow homeowners to create a tailored
                and attractive image for their properties.
              </Typography>
              <Header as="h3">
                <Header.Content>Sidewalk Concrete</Header.Content>
              </Header>
              <Typography>
                At Elite Concrete, we specialize in expertly pouring concrete
                sidewalks for clients across the Atlanta area. Our dedicated
                contractors are committed to delivering exceptional service at
                competitive prices. Equipped with our own state-of-the-art
                machinery, we ensure your concrete sidewalk projects are
                completed efficiently, within your budget and timeline.
              </Typography>
            </Grid.Column>
          </Grid.Row>
          {/* Concrete Removal and tear outs */}
          <Grid.Row>
            <Grid.Column width={8}>
              <Image
                src={concreteRemovalImg}
                alt="concrete removal"
                size="huge"
                rounded={true}
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <Header as="h3">
                <Header.Content>Concrete Removal</Header.Content>
              </Header>
              <Typography>
                As with anything though, there will come a time that you want
                all that concrete to be removed. To most people, that sounds
                like a great headache, but to us, it's a great opportunity!
              </Typography>
              <Header as="h3">
                <Header.Content>Concrete Tear Out</Header.Content>
              </Header>
              <Typography>
                Break up and remove concrete driveways and cement slabs of any
                size.
              </Typography>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Container>
  )
}

export default withLayout(AboutPage)
